<template>
  <div class="center">
    <div class="play border-left">
      <div class="flex">
        <p>САЙТ ЗАКРЫТ НА ТЕХНИЧЕСКОЕ ОБСЛУЖИВАНИЕ, <br>
        ПОПРОБУЙТЕ ЗАЙТИ ПОЗЖЕ</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
<style>
.center {
  border-radius: 10px;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}
.play {
  height: 150px;
  width: 500px;
  background: #1a2c3f;
  color: white;
  border-radius: 10px;
  border: 1px solid white;
}
.flex {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 110px;
  padding: 20px;
}
body {
  font-family: "TeX Gyre Bonum", serif;
  background-image: url('../assets/site/bg.webp');
  background-size: cover;
  color: #fff;
  background-attachment: fixed;
}
@font-face {
  font-family: "TeX Gyre Bonum", serif;
  src: url('../assets/font/TexgyrebonumRegular.otf');
  font-display: swap;
  font-weight: 400;
}
#app {
  font-family: "TeX Gyre Bonum", serif;
  font-style: normal;
  font-weight: 600;
  padding: 10px;
  font-size: 18px;
  line-height: 21px;
}
</style>
